<template>
  <div>
    <a-select :value="selectedValue" :size="size" style="width: 100%" allowClear @change="changeValue">
      <a-select-option v-for="item in dataItems" :key="item.id" :value="item.id">
        {{ item.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { countryOptionList } from "@/apis/option";

export default {
  props: ["value", "size"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      selectedValue: undefined,
      dataItems: [],
    };
  },
  methods: {
    changeValue(value) {
      this.selectedValue = value;
      this.$emit("change", value);
    },
  },
  mounted() {
    if (this.value !== undefined) {
      this.selectedValue = this.value;
    }
    countryOptionList().then((data) => (this.dataItems = data));
  },
};
</script>

<style scoped></style>
